import request from '../utils/request';
import qs from "qs";

export const getReportNumberList = param => {
	return request.post('/api/blackList/ReportNumberList', qs.stringify(param))
};


export const deleteReportNumber = param => {
	return request.post('/api/blackList/deleteReportNumber', qs.stringify(param))
};



export const getSnailsRecord = param => {
	return request.post('/api//numberPool/snailsRecord', qs.stringify(param))
};



export const getData = path => {
	return request.get('/api/invoke/getData?path=' + path, {
		responseType: 'blob'
	})
};

export const downloadReportNumber = param => {
	return request.post('/api/blackList/downloadReportNumber', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadSnails = param => {
	return request.post('/api/numberPool/downloadSnails', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadDownRecord = param => {
	return request.post('/api/numberPool/downloadDownRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};


export const findDownRecord = param => {
	return request.post('/api/numberPool/findDownRecord', qs.stringify(param))
};

export const downRecordDelete = param => {
	return request.post('/api/numberPool/downRecordDelete', qs.stringify(param))
};
export const getupdateDownRecord = param => {
	return request.post('/api/numberPool/updateDownRecord', qs.stringify(param))
};
export const findHippoRecord = param => {
	return request.post('/api/numberPool/findHippoRecord', qs.stringify(param))
};
export const blackListDeleteInputBox = param => {
	return request.post('/api/blackList/deleteInputBox', qs.stringify(param))
};




export const findHHRecord = param => {
	return request.post('/api/numberPool/findHHRecord', qs.stringify(param))
};


export const gethanghaiData = path => {
	return request.get('/api/invoke/getData?path=' + path, {
		responseType: 'blob'
	})
};
export const getverifyList = param => {
	return request.post('/api/blackList/verifyList', qs.stringify(param))
};

export const HMTodayRecord = param => {
	return request.post('/api/numberPool/HMTodayRecord', qs.stringify(param))
};

export const ytErrRecord = param => {
	return request.post('/api/numberPool/ytErrRecord', qs.stringify(param))
};

export const downloadYtErrRecord = param => {
	return request.post('/api/numberPool/downloadYtErrRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadHMTodayRecord = param => {
	return request.post('/api/numberPool/downloadHMTodayRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const addReport = param => {
	return request.post('/api/blackList/addReport', qs.stringify(param))
};